a.show-link {
    text-decoration: none;
    color: inherit; /* Inherit parent color */
}


.shows-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: black;
}

.shows-list h1 {
    padding: 40px 0 15px;
    color: #e23f2f;
    text-align: center;
    font-family: "Young Heart";
    text-shadow: 2px 3px var(--ivory1);
    letter-spacing: 5px;
    font-size: 6rem;
}

.shows-list ul {
    color: #e23f2f;
    border-radius: 12px;
    font-size: 2rem;
    list-style: none;
    font-family: "franklin-gothic-atf", sans-serif;
    font-weight: 800;
    font-style: normal;

    text-shadow: 1px 1px  var(--ivory1);

    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

li.show {
    padding: 15px;
    display: flex;
    flex-direction: row;
}

div.show-details {
    display: flex;
    flex-direction: row;
}

@media (max-width: 768px) {
    .shows-list ul {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }
}

@media (max-width: 365px) {
    .shows-list h1 {
        font-size: 4rem;
    }
}
