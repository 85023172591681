:root {
  --gray-black: #121212;
  --licorice: #220901ff;
  --blood-red: #621708ff;
  --penn-red: #941b0cff;
  --rust: #bc3908ff;
  --orange-web: #f6aa1cff;
  --navajo: #FFDB99;
  --ivory1: #FFEEDB;
  --chilli: #e23f2f;
  --ppink: #E8DAFB;
}

/*.animated-cursor {*/
/*  cursor: url('../../static/cursors/pink_flame/sprite_sheet.png') 0 0, auto;*/
/*  width: 32px; !* Adjust width and height to match your cursor frame size *!*/
/*  height: 32px;*/
/*  animation: cursor-animation 1s steps(2) infinite; !* Adjust animation duration and steps *!*/
/*}*/

/*@keyframes cursor-animation {*/
/*  0% { background-position: 0 0; }*/
/*  50% { background-position: -32px 0; } !* Adjust background-position based on frame width *!*/
/*  100% { background-position: -64px 0; } !* Adjust background-position based on frame width *!*/
/*}*/



.bg-licorice {
  background-color: var(--licorice);
}
.bg-blood-red {
  background-color: var(--blood-red);
}
.bg-penn-red {
  background-color: var(--penn-red);
}
.bg-rust {
  background-color: var(--rust);
}
.bg-orange-web {
  background-color: var(--orange-web);
}
.orange-web {
  color: var(--orange-web);
}
.active {
  color: var(--navajo) !important;
}
.display-none {
  display: none;
}
.display-block {
  display: block;
}
html, body {width: auto!important; overflow-x: hidden!important}



@font-face {
  font-family: 'Alagard'; /* Replace with your desired font name */
  src: url('../../fonts/alagard.ttf') format('truetype'); /* Adjust the font file path based on your directory structure */
  /* Add any additional font properties or styles here, such as font-weight or font-style */
}
@font-face {
  font-family: 'Young Heart'; /* Replace with your desired font name */
  src: url('../../fonts/Young Heart.ttf') format('truetype'); /* Adjust the font file path based on your directory structure */
  /* Add any additional font properties or styles here, such as font-weight or font-style */
}
@font-face {
  font-family: 'Deutsch Gothic'; /* Replace with your desired font name */
  src: url('../../fonts/Deutsch.ttf') format('truetype'); /* Adjust the font file path based on your directory structure */
  /* Add any additional font properties or styles here, such as font-weight or font-style */
}
@font-face {
  font-family: 'Old Celtiberians'; /* Replace with your desired font name */
  src: url('../../fonts/Old Celtiberians.otf') format('opentype'); /* Adjust the font file path based on your directory structure */
  /* Add any additional font properties or styles here, such as font-weight or font-style */
}
@font-face {
  font-family: 'Khaleefa'; /* Replace with your desired font name */
  src: url('../../fonts/Khaleefa.otf') format('opentype'); /* Adjust the font file path based on your directory structure */
  /* Add any additional font properties or styles here, such as font-weight or font-style */
}

.neon-button {
  animation: glow 2s linear infinite;
}

.neon-button:hover {
  text-shadow: 10px 10px 30px rgba(93, 52, 168)
}

@keyframes glow {
  0% {
    text-shadow: 5px 5px 30px rgb(93, 52, 168);
  }
  50% {
    text-shadow: 5px 5px 30px rgba(81, 223,210);
  }
  100% {
    text-shadow: 10px 10px 30px rgba(93, 52, 168);
  }
}

/* styles for meta pixel descriptor spans */
a {
  text-decoration:none;
}
.hidden-descriptor {
  display:none !important;
  text-decoration:none;
}

body {
  margin: 0;
  font-family: 'Deutsch Gothic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: var(--orange-web);
}

img.large-banner {
  max-height: 400px;
}


.nav-link {
  font-size: 1.3rem;
  padding: 5px 10px;
}

.nav-item {
  border-radius: 8px;
  display: inline-block;
}

a:hover {
  text-shadow: 1px 1px rgba(247, 191, 5);
}

@media screen and (max-width: 300px) {
  /* CSS styles for screens under 280 pixels */
  /* Add your styles here */
  h4.card-text {
    font-size: 3rem;
  }
  /* Add more styles as needed */
}


@media(max-width: 300px) {
    body div {
      background-color: black !important;
    }
    body {
      background-color: black !important;
    }
}