
.square-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*max-width: 10rem; !* Adjust the desired width *!*/
    /*max-hieght: 10rem; !* Adjust the desired height *!*/
    padding: 5px;
}

.square-image {
    max-width: 100%;
    max-height: 100%;
    background-color: black;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
}

.gallery {
    padding: 100px 25px 25px;
}

.large-banner {
    min-width: 200px;
}
.nav-link {display: inline-block}

.gallery h1 {
    padding-bottom: 20px;
    color: white;
    text-align: center;
    font-family: "Old Celtiberians";
    text-shadow: 5px 5px var(--chilli);
    letter-spacing: 5px;
    font-size: 4rem;
}

@media (max-width: 365px) {
    .gallery h1 {
        font-size: 3rem;
    }
}



.neon-button:hover {
    animation: glow 2s linear infinite;
}

