.HomeLink, .navigation-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #e23f2f;
    text-shadow: 3px 2px #fff8e2df;
    max-width: 200px;
    font-family: "franklin-gothic-atf", sans-serif;
    font-weight: 800;
    font-style: normal;
}
.navigation-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}


.navigation-links-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-link {
    margin-right: 10px;
}

.nav-link a {
    text-decoration: none;
}


ul.navigation-links-list {
    padding: 20px 0 0 0;
}
.navigation-links-list li {
    margin: 0;
}
.navigation-links-list a {
    font-size: 2.510rem;
}

@media(min-width:400px) {
    .nav-link {
        width: 212px;
    }
}


@media(max-width: 496px) {
    .navigation-links-list a {
        font-size: 1.4rem;
    }
    .navigation-links-list li {
        padding-top: 0;
    }
    
    .navigation-links-list {
        height:30%
    }
}
.navigation-links-list a:hover {
    text-shadow: 1px 1px rgba(247, 191, 5);
}





@media(max-width: 344px) {
    .navigation-wrapper {
        flex-direction: column;
    }
   .navigation-menu, .navigation-links-list{
        max-width: 100%;
    }

}