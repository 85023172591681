div.press-kit {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    font-size: 1.5rem;
    padding:25px
}
.bbox {
    background-color: #e23f2f;
    color: black;
    display: flex;
    justify-content: center;
    max-width: 700px;
    border-radius: 20px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
}

.band-photo {
    display: flex;
    flex-direction: row;
    padding-right: 25px;
}
.band-photo img {
    max-width: 300px;
}

.bio {
    padding: 25px 50px;
    font-family: "Helvetica Neue", sans-serif;
    text-shadow: 2px 2px black;
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.bio .segment {
    display: flex;
}

.bio h1 {
    letter-spacing: 5px;
    text-align: center;
    font-family: "Young Heart";
    text-shadow: 2px 3px var(--ivory1);
    font-size: 4rem;
    color: black;
}

.bio-photos {
    padding: 25px;
}
.bio-photos img {
    max-width: 300px;
    margin:10px;
    border-radius: 15px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1060px) {
    .bio-photos img {
        max-width: 200px;
    }
}

@media (max-width: 760px) {
    .bio-photos img {
        max-width: 90%;
    }
    .bio-photos {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 400px) {
    .bio {
        font-size: 1.2rem;
    }
}

@media (max-width: 300px) {
    .bio h1 {
        color: var(--chilli);
        font-family: "Young Heart";
        text-shadow: 2px 3px var(--ivory1);
    }

    .bio {
        font-size: 1.3rem;
        padding: 5px;
        margin-left: 10%;
    }


}
