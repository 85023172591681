

.discography {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;    
}

div.release-image {
    padding-bottom: 20px;
}

img.release-image {
    border-radius: 2px;
}
.release {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem; 
}

.release h4.release-links {
    font-size: 3rem; 
}

.discography {
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social-links {
    position: relative;
    display: flex;
    font-size: 3rem;
    
}

a.social-link {
    color: rgba(255,255, 255, 0.85) !important;
    text-decoration:none;
}

.social-link i {
    padding: 0 10px 0 10px;
}

@media(min-width: 992px) {

    .social-links {
        flex-direction: column;
        margin-right:20px
    }

    .discography .info {
        display: flex;
        flex-direction: row;
    }
    .discography img {
        max-height: 400px;
        padding: 0;
    }

    .blurb {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px;
        text-align: center;
    }

    .social-links {
        color: #121212;
    }

}

@media (max-width: 991px) {

    .discography {
        flex-direction: column;
        align-items: center;
    }
    .discography .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;
    }
    .discography img {
        max-width: 550px;
    }
    
    .social-links {
        width: 200px;
        justify-content: space-evenly;
    }
}

@media (max-width: 660px) {
    .discography img {
        max-width: 350px;
    }
}

@media (max-width: 400px) {
    .discography img {
        max-width: 270px;
    }
}

@media (max-width: 300px) {
    a.social-link {
        font-size: 3rem;
        padding: 30px 0 3px;
    }
}

.hidden-descriptor {
    display:none !important;
    text-decoration:none;
}


.release-info {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    background-color: black;
    color: white;
    font-weight: 500;

    font-family: "Verdana";
}

.discography h3 {
    font-size: 3rem;
    font-family: "Deutsch Gothic";
    font-weight: 100;
    text-space: 10px;
    text-align: center;
}
